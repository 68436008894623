import tokens from "./boards.json";
const theme = tokens.arma;
export const armaBoard = {
    board: theme.board.value,
    pieces: {
        bp: theme.pieces.bp.value,
        br: theme.pieces.br.value,
        bn: theme.pieces.bn.value,
        bb: theme.pieces.bb.value,
        bq: theme.pieces.bq.value,
        bk: theme.pieces.bk.value,
        wp: theme.pieces.wp.value,
        wr: theme.pieces.wr.value,
        wn: theme.pieces.wn.value,
        wb: theme.pieces.wb.value,
        wq: theme.pieces.wq.value,
        wk: theme.pieces.wk.value,
    },
    check: {
        color: theme.mod.check.value,
    },
    hover: {
        color: theme.mod.drag.value,
    },
    lastMove: {
        color: theme.mod.last_move.value,
    },
    painting: {
        rect: {
            green: { color: theme.mod.paint.value },
            red: undefined,
            blue: undefined,
            yellow: undefined,
        },
        arrow: {
            green: { color: theme.arrow.paint_arrow.value },
            red: undefined,
            blue: undefined,
            yellow: undefined,
        },
    },
    premove: {
        from: {
            color: theme.mod.premove.value,
        },
        to: {
            border: `3px dashed ${theme.mod.premove.value}`,
        },
    },
    selected: {
        color: theme.mod.selected.value,
    },
    arrows: {
        analysis: {
            fill: theme.arrow.analysis.value,
            stroke: theme.arrow.analysis.value,
        },
        lastMove: {
            black: {
                fill: theme.arrow.black.value,
                stroke: theme.arrow.black.value,
            },
            white: {
                fill: theme.arrow.black.value,
                stroke: theme.arrow.black.value,
            },
        },
    },
    possibleMove: {
        white: {
            squareColor: theme.piece.white_fill.value,
            dotColor: theme.piece.white_fill.value,
            attack: {
                outer: theme.piece.white_fill.value,
                inner: theme.piece.white_outline.value,
            },
        },
        black: {
            squareColor: theme.piece.black_fill.value,
            dotColor: theme.piece.black_fill.value,
            attack: {
                outer: theme.piece.black_fill.value,
                inner: theme.piece.black_outline.value,
            },
        },
    },
    title: {
        vertical: {
            oddClr: theme.piece.black_fill.value,
            evenClr: theme.piece.white_fill.value,
        },
        horizontal: {
            oddClr: theme.piece.white_fill.value,
            evenClr: theme.piece.black_fill.value,
        },
    },
};
