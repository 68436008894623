import { useEffect, useMemo } from 'react';

import { appActions, helpersActions } from '@store/storeshed';
import { gtmInit } from '@utils/_gtm';

import { ApplicationContext } from './_context';
import {
  IApplicationContext,
  IApplicationContextProviderProps,
} from './_types';
import { useI18n } from './i18n/_useI18n';
import { useTheme } from './theme/_useTheme';
import { useUser } from './user/_useUser';

export const ApplicationContextProvider: React.FC<
  IApplicationContextProviderProps
> = ({ initProps, children }) => {
  const theme = useTheme(initProps.theme);
  const i18n = useI18n(initProps.locale);
  const user = useUser();

  const value = useMemo<IApplicationContext>(
    () => ({
      theme: theme.current,
      switchTheme: theme.switchTheme,
      locale: i18n.locale,
      localization: i18n.localization,
      changeLocale: i18n.changeLocale,
      userData: user.data,
      userLoaded: user.loaded,
    }),
    [theme, i18n, user]
  );

  useEffect(() => {
    helpersActions.getPlans();
    appActions.getNGAllRegions();

    gtmInit();
  }, []);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};
