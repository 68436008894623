import { useCallback, useEffect, useState } from 'react';

import { serverService } from '@services/_server.service';
import { IUserData, lsKeys } from '@types';
import { deleteCookie, getCookie } from '@utils/_cookies';

import {
  authActions,
  fideSubscriptionActions,
  loginActions,
  userDataActions,
} from '@store/storeshed';

import { findProPlan } from '@utils/_helpers/_common';
import { IApplicationUserData } from './_types';

export const useUser = () => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<IApplicationUserData | undefined>(undefined);

  //todo избавиться от IUserData и storeshed
  const setUserData = useCallback((data?: IUserData) => {
    if (data) {
      setData(data as IApplicationUserData);

      userDataActions.setUserData(data);
      authActions.setUserUid(data.uid);

      const currentFide = findProPlan(data);
      fideSubscriptionActions.setProSubscription({
        fide_id: data.fide_id,
        is_active: currentFide ? currentFide.is_active : false,
        verified: data.fide_verified_status,
        free_requested: data.free_account_requested,
        data: currentFide,
      });

      authActions.setLogged(true);
    } else {
      loginActions.resetUserStore();
      authActions.loadUid();
    }

    userDataActions.setDataRequest(false);
  }, []);

  const getUserData = useCallback(async () => {
    try {
      const jwt = getCookie(lsKeys.JWT);

      if (!jwt) {
        setUserData(undefined);
        return;
      }

      authActions.setToken(jwt);
      const data = await serverService.getUserData(jwt);

      if ('code' in data && 'detail' in data) {
        setUserData(undefined);

        // Если пользователя забанили, то чистим cookie
        if (data?.code === 'user_inactive') {
          deleteCookie(lsKeys.USER_UID);
          deleteCookie(lsKeys.JWT);
        }

        return;
      }

      setUserData(data);
    } catch (err) {
      setUserData(undefined);
      console.log(err);
    } finally {
      setLoaded(true);
    }
  }, [setUserData]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return { loaded, data };
};
