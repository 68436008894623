import { boards, ePaintingColor } from ".";
import { cgDataTypes, cgTagNames, ePossibleMovesTheme } from "../ui";
function getPromotionStyles(themeName) {
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PROMOTION}] {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 0;
      width: calc(100% / 8);
      height: calc(100% / 2);
      background-size: 200% 800%;
      background-position: 25% 0%;
      background-color: rgba(25, 25, 25, 0.9);
      box-shadow: 0 12px 32px 0px rgba(0, 0, 0, 0.4);
      z-index: 10;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PROMOTION}] [data-cg-type=${cgDataTypes.PIECE}] {
      width: 100%;
      height: 25%;
      background-size: cover;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PROMOTION}].b {
      top: auto;
      bottom: 0;
      flex-direction: column-reverse;
      background-color: rgba(249, 247, 243, 0.9);
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PROMOTION}].rotated [data-cg-type=${cgDataTypes.PIECE}] {
      transform: rotate(180deg);
    }
  `;
}
function getSquareStyles(dataType, themeName, squareStyle) {
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${dataType}] {
      background-color: ${(squareStyle === null || squareStyle === void 0 ? void 0 : squareStyle.color) || "transparent"};
      border: ${(squareStyle === null || squareStyle === void 0 ? void 0 : squareStyle.border) || "1px solid inherit"};
      opacity: ${(squareStyle === null || squareStyle === void 0 ? void 0 : squareStyle.opacity) || "1"};
    }

    @media screen and (max-width: 700px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${dataType}] {
        border-width: 3px;
      }
    }

    @media screen and (max-width: 500px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${dataType}] {
        border-width: 2px;
      }
    }
  `;
}
function getPossibleMoveSquareStyles(themeName, theme) {
    var _a, _b;
    const { black, white } = theme.possibleMove || {
        black: {
            dotColor: "#181818",
            squareColor: "orange",
            attack: {
                outer: "#181818",
                inner: "#F9F1E4",
            },
        },
        white: {
            dotColor: "#F9F1E4",
            squareColor: "orange",
            attack: {
                outer: "#F9F1E4",
                inner: "#181818",
            },
        },
    };
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.move::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7%;
      height: 7%;
      border-radius: 50%;
      content: " ";
      transform: translate(-50%, -50%);
      background-color: black;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.move.b::before {
      background-color: ${black.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.move.w::before {
      background-color: ${white.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling::before,
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7%;
      height: 7%;
      border-radius: 50%;
      content: "";
      transform: translate(-50%, -50%);
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling::before {
      transform: translate(-140%, -50%);
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling::after {
      transform: translate(40%, -50%);
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling.b::before,
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling.b::after {
      background-color: ${black.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling.w::before,
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.castling.w::after {
      background-color: ${white.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture::before {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14%;
      height: 14%;
      border-radius: 50%;
      content: " ";
      transform: translate(-50%, -50%);
      background-color: black;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 7%;
      height: 7%;
      border-radius: 50%;
      content: " ";
      transform: translate(-50%, -50%);
      background-color: black;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture.b::before {
      background-color: ${black.attack ? black.attack.outer : black.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture.b::after {
      background-color: ${black.attack ? black.attack.inner : white.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture.w::before {
      background-color: ${white.attack ? white.attack.outer : black.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.DOTS}.capture.w::after {
      background-color: ${white.attack ? white.attack.inner : white.dotColor};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.SQUARES}.b {
      background-color: ${((_a = theme.possibleMove) === null || _a === void 0 ? void 0 : _a.black.squareColor) || "#F1A265"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.POSSIBLE_MOVE}].${ePossibleMovesTheme.SQUARES}.w {
      background-color: ${((_b = theme.possibleMove) === null || _b === void 0 ? void 0 : _b.white.squareColor) || "#F1A265"};
    }
  `;
}
function getHoverSquareStyles(themeName, theme) {
    var _a, _b, _c;
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.HOVER}]::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: ${((_a = theme.hover) === null || _a === void 0 ? void 0 : _a.color) || "transparrent"};
      border: ${((_b = theme.hover) === null || _b === void 0 ? void 0 : _b.border) || "none"};
      opacity: ${((_c = theme.hover) === null || _c === void 0 ? void 0 : _c.opacity) || "1"};
    }

    @media screen and (max-width: 700px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.HOVER}]::after {
        border-width: 3px;
      }
    }

    @media screen and (max-width: 500px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.HOVER}]::after {
        border-width: 2px;
      }
    }

    @media only screen and (hover: none) and (pointer: coarse) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.HOVER}]::before {
        position: absolute;
        top: 50%;
        left: 50%;
        content: " ";
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.5);
        width: 200%;
        height: 200%;
        border-radius: 50%;
      }
    }
  `;
}
function getPremoveSquareStyles(themeName, theme) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.PREMOVE}].from {
      background-color: ${((_b = (_a = theme.premove) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.color) || "transparent"};
      border: ${((_d = (_c = theme.premove) === null || _c === void 0 ? void 0 : _c.from) === null || _d === void 0 ? void 0 : _d.border) || "none"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.PREMOVE}].to {
      background-color: ${((_f = (_e = theme.premove) === null || _e === void 0 ? void 0 : _e.to) === null || _f === void 0 ? void 0 : _f.color) || "transparent"};
      border: ${((_h = (_g = theme.premove) === null || _g === void 0 ? void 0 : _g.to) === null || _h === void 0 ? void 0 : _h.border) || "1px dashed inherit"};
      opacity: ${((_k = (_j = theme.premove) === null || _j === void 0 ? void 0 : _j.to) === null || _k === void 0 ? void 0 : _k.opacity) || "1"};
    }

    @media screen and (max-width: 500px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.PREMOVE}].to {
        border-width: 2px;
      }
    }

    @media screen and (max-width: 700px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.squares.PREMOVE}].to {
        border-width: 3px;
      }
    }
  `;
}
function getTitleStyles(themeName, theme) {
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES} .col span:nth-child(even), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .col span:nth-child(even) {
      color: ${theme.title ? theme.title.vertical.oddClr : "black"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES} .col span:nth-child(odd), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .col span:nth-child(odd) {
      color: ${theme.title ? theme.title.vertical.evenClr : "white"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES} .row span:nth-child(odd), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .row span:nth-child(odd) {
      color: ${theme.title ? theme.title.horizontal.oddClr : "white"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES} .row span:nth-child(even), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .row span:nth-child(even) {
      color: ${theme.title ? theme.title.horizontal.evenClr : "black"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES}.rotated .col span:nth-child(even), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .rotated .col span:nth-child(even) {
      color: ${theme.title ? theme.title.vertical.evenClr : "white"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES}.rotated .col span:nth-child(odd), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .rotated .col span:nth-child(odd) {
      color: ${theme.title ? theme.title.vertical.oddClr : "black"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES}.rotated .row span:nth-child(odd), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .rotated .row span:nth-child(odd) {
      color: ${theme.title ? theme.title.horizontal.evenClr : "black"};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] ${cgTagNames.TITLES}.rotated .row span:nth-child(even), [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .cg-titles .rotated .row span:nth-child(even) {
      color: ${theme.title ? theme.title.horizontal.oddClr : "white"};
    }
  `;
}
function getSquaresStyles(themeName, theme) {
    return `
    ${getTitleStyles(themeName, theme)}
    ${getPremoveSquareStyles(themeName, theme)}
    ${getHoverSquareStyles(themeName, theme)}
    ${getPossibleMoveSquareStyles(themeName, theme)}
    ${getSquareStyles(cgDataTypes.squares.CHECK, themeName, theme.check)}
    ${getSquareStyles(cgDataTypes.squares.SELECTED, themeName, theme.selected)}
    ${getSquareStyles(cgDataTypes.squares.LAST_MOVE, themeName, theme.lastMove)}
  `;
}
function getArrowStyles(themeName, theme) {
    var _a, _b, _c;
    const { white, black } = ((_a = theme.arrows) === null || _a === void 0 ? void 0 : _a.lastMove) || {
        black: { fill: "#181818", stroke: "#181818" },
        white: { fill: "#F9F1E4", stroke: "#F9F1E4" },
    };
    const painting = theme.painting;
    const userDrawing = {
        rect: {
            [ePaintingColor.GREEN]: { color: "#C8F77A", opacity: 0.5 },
            [ePaintingColor.RED]: { color: "#BE3F37", opacity: 0.5 },
            [ePaintingColor.BLUE]: { color: "#2F329F", opacity: 0.5 },
            [ePaintingColor.YELLOW]: { color: "#FFA800", opacity: 0.5 },
        },
        arrow: {
            [ePaintingColor.GREEN]: { color: "#C8F77A", opacity: 0.7 },
            [ePaintingColor.RED]: { color: "#BE3F37", opacity: 0.7 },
            [ePaintingColor.BLUE]: { color: "#2F329F", opacity: 0.7 },
            [ePaintingColor.YELLOW]: { color: "#FFA800", opacity: 0.7 },
        },
    };
    if (painting) {
        Object.values(ePaintingColor).forEach((key) => {
            var _a, _b, _c, _d;
            const $key = key;
            userDrawing.rect[$key].color = ((_a = painting.rect[$key]) === null || _a === void 0 ? void 0 : _a.color) || userDrawing.rect[$key].color;
            userDrawing.rect[$key].opacity = ((_b = painting.rect[$key]) === null || _b === void 0 ? void 0 : _b.opacity) || 0.5;
            userDrawing.arrow[$key].color = ((_c = painting.arrow[$key]) === null || _c === void 0 ? void 0 : _c.color) || userDrawing.arrow[$key].color;
            userDrawing.arrow[$key].opacity = ((_d = painting.arrow[$key]) === null || _d === void 0 ? void 0 : _d.opacity) || 0.7;
        });
    }
    else if ((_b = theme.arrows) === null || _b === void 0 ? void 0 : _b.drawing) {
        Object.values(ePaintingColor).forEach((key) => {
            var _a, _b, _c, _d;
            const $key = key;
            userDrawing.rect[$key].color = ((_b = (_a = theme === null || theme === void 0 ? void 0 : theme.arrows) === null || _a === void 0 ? void 0 : _a.drawing) === null || _b === void 0 ? void 0 : _b.fill) || userDrawing.rect[$key].color;
            userDrawing.rect[$key].opacity = 0.5;
            userDrawing.arrow[$key].color = ((_d = (_c = theme === null || theme === void 0 ? void 0 : theme.arrows) === null || _c === void 0 ? void 0 : _c.drawing) === null || _d === void 0 ? void 0 : _d.fill) || userDrawing.arrow[$key].color;
            userDrawing.arrow[$key].opacity = 0.7;
        });
    }
    const analysis = ((_c = theme.arrows) === null || _c === void 0 ? void 0 : _c.analysis) || { fill: "#00f0ff", stroke: "#00f0ff" };
    const lmArrowStyles = `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .arrows #lm_arrow_b {
      stroke: ${black === null || black === void 0 ? void 0 : black.stroke};
      fill: ${black === null || black === void 0 ? void 0 : black.fill};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .arrows #lm_arrow_w {
      stroke: ${white === null || white === void 0 ? void 0 : white.stroke};
      fill: ${white === null || white === void 0 ? void 0 : white.fill};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .arrows .last-move.b {
      stroke: ${black === null || black === void 0 ? void 0 : black.stroke};
      fill: ${black === null || black === void 0 ? void 0 : black.fill};
      stroke-width: ${black === null || black === void 0 ? void 0 : black.strokeWidth};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .arrows .last-move.w {
      stroke: ${white === null || white === void 0 ? void 0 : white.stroke};
      fill: ${white === null || white === void 0 ? void 0 : white.fill};
      stroke-width: ${white === null || white === void 0 ? void 0 : white.strokeWidth};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] #lm_arma_arrow {
      fill: #ff2000;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .arma {
      stroke: #ff2000;
    }
  `;
    const analysisArrowStyles = `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .analysis {
      stroke: ${analysis.stroke};
      fill: ${analysis.fill};
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] #a_arrow {
      /* stroke: ${analysis.stroke}; */
      fill: ${analysis.fill};
    }
  `;
    let userDrawingStyles = ``;
    Object.values(ePaintingColor).forEach((key) => {
        const $key = key;
        userDrawingStyles += ` 
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .user_drawing #user_arrow_${key} {
      fill: ${userDrawing.arrow[$key].color};
    }
    
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .user_drawing polyline.user_arrow_${key} {
      stroke: ${userDrawing.arrow[$key].color};
      opacity: ${userDrawing.arrow[$key].opacity};
    }
    
     [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] .user_drawing rect.${key} {
        fill: ${userDrawing.rect[$key].color};
        opacity: ${userDrawing.rect[$key].opacity};
    }
  `;
    });
    return `
    ${lmArrowStyles}
    ${analysisArrowStyles}
    ${userDrawingStyles}
  `;
}
function getPiecesStyles(themeName, theme) {
    const { pieces } = theme;
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}] {
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].bp {
      background-image: url(${pieces.bp});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].br {
      background-image: url(${pieces.br});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].bb {
      background-image: url(${pieces.bb});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].bn {
      background-image: url(${pieces.bn});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].bq {
      background-image: url(${pieces.bq});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].bk {
      background-image: url(${pieces.bk});
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wp {
      background-image: url(${pieces.wp});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wr {
      background-image: url(${pieces.wr});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wb {
      background-image: url(${pieces.wb});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wn {
      background-image: url(${pieces.wn});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wq {
      background-image: url(${pieces.wq});
    }
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].wk {
      background-image: url(${pieces.wk});
    }

    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] [data-cg-type=${cgDataTypes.PIECE}].dragging {
      filter: drop-shadow(0px 5px 5px rgb(0 0 0 / 0.6));
    }
  `;
}
function getBoardStyles(themeName, theme) {
    return `
    [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] {
      position: relative;
      display: flex;
      box-sizing: border-box;
      cursor: pointer;
      margin: 0;
      padding: 0;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(${"board" in theme ? theme.board : theme.lBoard});
    }

    @media screen and (max-width: 1380px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] {
        background-image: url(${"board" in theme ? theme.board : theme.mBoard});
      }
    }

    @media screen and (max-width: 768px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] {
        background-image: url(${"board" in theme ? theme.board : theme.sBoard});
      }
    }

    @media screen and (max-width: 400px) {
      [data-cg-type=${cgDataTypes.BOARD}][data-theme=${themeName}] {
        background-image: url(${"board" in theme ? theme.board : theme.xsBoard});
      }
    }

    ${getPiecesStyles(themeName, theme)}
    ${getArrowStyles(themeName, theme)}
    ${getSquaresStyles(themeName, theme)}
    ${getPromotionStyles(themeName)}
  `;
}
export function getBoardsStyles() {
    const _boardStyles = boards.map(({ key, board }) => getBoardStyles(key, board));
    return _boardStyles.join("");
}
