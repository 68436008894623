import { syncedDate } from '@utils/_helpers/_common';
import { lsKeys } from '@types';
import { TMainStore } from '@store/storeshed';
import { setCookie, getCookie } from '@utils/_cookies';
import { setLSItem } from '@utils/_local_storage';
import { userService } from '@services/_user.service';

export const createAuthActions = () => {
  /**
   * Задает токен пользователя.
   * @param {string} token - токен пользователя.
   */
  const setToken = ({ authStore }: TMainStore, token: string | null) => {
    authStore.dispatch('token', token);
  };

  /**
   * Задает uid пользователя
   * @param {object} uid  - uid юзера.
   */
  const setUserUid = ({ authStore }: TMainStore, uid: string) => {
    authStore.dispatch('uid', uid);

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      setCookie(lsKeys.USER_UID, uid);
    }
  };

  /**
   * Задает статус пользователя, залогинен он или нет.
   * @param {boolean} isLogged- залогинен пользователь или нет.
   */
  const setLogged = ({ authStore }: TMainStore, isLogged: boolean) => {
    authStore.dispatch('logged', isLogged);
  };

  /**
   *  Загружает данные, не зависящие от авторизации
   */
  const loadUid = async ({ authStore }: TMainStore) => {
    const uid = getCookie(lsKeys.USER_UID) || null;
    try {
      const {
        data: { uid: newUid },
      } = await userService.getUserUid(uid);

      if (newUid) {
        authStore.dispatch('uid', newUid);

        setLSItem(lsKeys.USER_UID, newUid);
        setCookie(lsKeys.USER_UID, newUid);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * ex checkToken
   * TODO: вынести отсюдыва
   * @param mainStore
   * @param param1
   * @returns
   */
  const isTokenNeedsToUpdate = async (
    mainStore: TMainStore,
    tokenSetTime: number
  ): Promise<boolean> => {
    if (tokenSetTime == null) return false;
    const now = syncedDate().getTime();
    const diff = now - tokenSetTime;

    // Проверяем, прошла ли неделя с момента записи
    const MINUTE = 1000 * 60;
    const DAY = 1000 * 60 * 60 * 24;

    const needsToUpdateToken =
      process.env.NEXT_PUBLIC_ENV === 'master'
        ? diff / DAY >= 7
        : diff / MINUTE >= 2;

    return needsToUpdateToken;
  };

  const refreshToken = async ({ authStore }: TMainStore, token: string) => {
    try {
      const { data } = await userService.refreshToken(token);

      console.log('refreshed token', data.token);
      // console.log('refreshed token', status, ok, data.token);

      if (data) {
        authStore.dispatch('token', data.token);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    setToken,
    setUserUid,
    setLogged,
    loadUid,
    refreshToken,
    isTokenNeedsToUpdate,
  };
};
