import React, { useEffect } from 'react';

import { lsKeys } from '@types';

import { userService } from '@services/_user.service';
import { authStore } from '@store/storeshed';
import { deleteCookie, getCookie, setCookie } from '@utils/_cookies';
import { useHandleVhVwChange } from '@utils/hooks/_useHandleVhVwChange.hook';
import { useSystemPopups } from '@utils/hooks/_useSystemPopups.hook';
import { SystemPopups } from './_SystemPopups';
import { useProBoardSettings } from './_useProBoardSettings.hook';

export const Settings: React.FC = () => {
  const { alert, confirm } = useSystemPopups();

  useHandleVhVwChange();
  useProBoardSettings();

  useEffect(() => {
    const unsub = authStore.on('token', (token) => {
      const cookieToken = getCookie(lsKeys.JWT);

      if (token === null) {
        if (cookieToken) {
          console.log('remove jwt');
          deleteCookie(lsKeys.JWT);
          userService.ajax.removeHeaders('Authorization');
        }
        return;
      }

      userService.ajax.addHeaders({
        Authorization: token ? `JWT ${token}` : '',
      });

      if (token !== cookieToken) {
        console.log('Setting token');

        setCookie(lsKeys.JWT, token);
        // setLSItem(lsKeys.JWT, token);
        // Записываем время обновления токена для последующего рефреша на сервере
        // setLSItem(lsKeys.JWT_SET_TIME, syncedDate().getTime());
      }

      // refreshData();
    });

    return () => {
      unsub();
    };
  }, []);

  return (
    <div>
      <SystemPopups alert={alert} confirm={confirm} />
    </div>
  );
};
