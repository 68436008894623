import { useCallback, useMemo } from 'react';
import { createSocialAuthRedirectUri } from '../_createSocialAuthRedirectUri';
import { useRouter } from 'next/router';

export const appleRedirectUri = createSocialAuthRedirectUri('/api/auth/apple');

export const googleRedirectUri =
  createSocialAuthRedirectUri('/api/auth/google');
export const facebookRedirectUri =
  createSocialAuthRedirectUri('/api/auth/facebook');

interface ICreateAuthLinkOpts {
  url: string;
  scope: string;
  clientId: string;
  redirectUrl: string;
  responseType: string;
}

/**
 * Хук, отдающий функции для редиректа при авторизации через соцсети
 * @returns
 */
export const useSocialAuthActions = () => {
  const router = useRouter();

  console.log(router.asPath, router.pathname);

  const state = useMemo(() => {
    return router.asPath.split('?')[0];
  }, [router.asPath]);

  console.log(state);

  const createAuthLink = useCallback(
    (opts: ICreateAuthLinkOpts) => {
      const { url, clientId, redirectUrl, responseType, scope } = opts;

      return `${url}&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${state}&response_type=${responseType}&scope=${scope}`;
    },
    [state]
  );

  const createAuthFunction = useCallback(
    (opts: ICreateAuthLinkOpts) => () => {
      const link = createAuthLink(opts);

      window.location.replace(link);
    },
    [createAuthLink]
  );

  const authWithApple = useMemo(
    () =>
      createAuthFunction({
        scope: 'email+name',
        responseType: 'code',
        redirectUrl: appleRedirectUri,
        clientId: process.env.NEXT_PUBLIC_APPLE_OAUTH_CLIENT_ID || '',
        url: `https://appleid.apple.com/auth/authorize?response_mode=form_post`,
      }),
    [createAuthFunction]
  );

  const authWithGoogle = useMemo(
    () =>
      createAuthFunction({
        responseType: 'code',
        scope: 'openid profile email',
        redirectUrl: googleRedirectUri,
        clientId: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID || '',
        url: `https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=online`,
      }),
    [createAuthFunction]
  );

  const authWithFacebook = useMemo(
    () =>
      createAuthFunction({
        scope: 'email public_profile',
        responseType: 'token',
        redirectUrl: facebookRedirectUri,
        clientId: process.env.NEXT_PUBLIC_FACEBOOK_OAUTH_CLIENT_ID || '',
        url: `https://www.facebook.com/v16.0/dialog/oauth?`,
      }),
    [createAuthFunction]
  );

  return { authWithApple, authWithGoogle, authWithFacebook };
};
