type TQueryValue = string | string[] | number | number[] | boolean | undefined;

export interface IURLMap {
  [queryParameter: string]: TQueryValue;
}

export const combineURLParamsByMap = (urlBase: string, urlMap: IURLMap) => {
  let url = `${urlBase}`;

  Object.entries(urlMap)
    .filter(([_, value]) => value !== undefined)
    .forEach(([queryParameter, value], idx) => {
      if (Array.isArray(value)) {
        value.forEach((item, iIdx) => {
          url += `${idx > 0 || iIdx > 0 ? `&` : `?`}${queryParameter}=${item}`;
        });
      } else {
        url += `${idx > 0 ? `&` : `?`}${queryParameter}=${value}`;
      }
    });

  return url;
};

export const getNGRegionUrl = (region?: string | null) => {
  if (!region || region === 'basic') {
    return process.env.NEXT_PUBLIC_NG_API_URL;
  }

  return process.env.NEXT_PUBLIC_NG_REGION_API_URL?.replace('region', region);
};
