import { getMoveInfo } from "../move";
import { figureType, moveTypes } from "../_constants";
export const parseAnalysisItem = (item, lineItems = 3) => {
    return new Promise((res) => {
        const { fen, depth, multipv } = item;
        const result = {
            fen,
            depth,
            multipv: [],
        };
        multipv.forEach(({ line, line_eval, mate_in }, index) => {
            let $fen = fen;
            const $line = [];
            for (let i = 0; i < line.length; i++) {
                if (i >= lineItems)
                    break;
                const $lineItem = line[i];
                const { san, moveData, fen } = getMoveInfo($fen, $lineItem);
                $fen = fen;
                const $from = moveData.type === moveTypes.CASTLING ? moveData.king.from : moveData.from;
                const $to = moveData.type === moveTypes.CASTLING ? moveData.king.to : moveData.to;
                const $san = san;
                const $type = moveData.type === moveTypes.CASTLING ? figureType.KING : moveData.piece;
                $line.push({
                    fen,
                    lan: $lineItem,
                    from: $from,
                    to: $to,
                    san: $san,
                    type: $type,
                });
            }
            result.multipv.push({
                line_eval,
                mate_in,
                line: $line,
            });
        });
        res(result);
    });
};
