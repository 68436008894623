import { ISounds, ITimeControl } from '@types';
import { BaseService } from './_base.service';
import { getNGRegionUrl } from '@utils/_url_helpers';
import { httpInstance } from '.';
import { IGameState, INGPlayerLatencies } from '@store/context/_types';

// TODO: fix getSounds and getLocalization
export class AppService extends BaseService {
  // /**
  //  * Осуществляет запрос о получении файла с локализацией.
  //  *  @param {LocaleType} locale - текущая локаль
  //  */
  // getLocalization(locale: LocaleType) {
  //   return this.ajax.get<ILocalization>(
  //     `http://localhost:3002/locales/${locale}.json` //TODO: check if works
  //   );
  // }

  /**
   * Выполняет запрос на получение данных звуков игры
   */
  getSounds() {
    return this.ajax.get<ISounds>(`${location.origin}/sounds/game_sounds.json`);
  }

  /**
   * Осуществляет запрос о получении списка таймкотролей.
   */
  getTimeControls() {
    return this.ajax.get<ITimeControl[]>(`timecontrols/`);
  }

  /**
   * Осуществляет запрос о получении активных игр на ng.
   * @param {string} userUid - uid пользователя
   */
  getNGPlayerGames({
    userUids,
    gameIds,
  }: {
    userUids?: string[];
    gameIds?: string[];
  }) {
    return this.ajax.post<IGameState[]>(
      `${process.env.NEXT_PUBLIC_NG_API_URL}api/v1/games/getByCriteria/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          includedPIds: userUids ? userUids : undefined,
          includedGIds: gameIds ? gameIds : undefined,
        }),
      }
    );
  }

  /**
   * Осуществляет запрос о получении регионов для списка игр
   * @param {string[]} gameIds - id досок
   */
  getRegionsByGIds(gameIds: string[]) {
    return this.ajax.post<{ [boardId: string]: string }>(
      `${process.env.NEXT_PUBLIC_NG_CENTRAL_API}regions/getByGIds/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(gameIds),
      }
    );
  }

  /**
   * Осуществляет запрос о получении списка регионов ng.
   */
  getNGAllRegions() {
    return this.ajax.get<string[]>(
      `${process.env.NEXT_PUBLIC_NG_CENTRAL_API}regions/all/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /**
   * Осуществляет запрос о получении времени обращения до регионального сервера.
   * @param {string} region - id региона
   */
  getNGRegionLiveness(region: string) {
    return this.ajax.get<void>(
      `${getNGRegionUrl(region)}common/api/v1/utils/liveness/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }

  /**
   * Осуществляет запрос о сохранении задержек для регионов ng.
   * @param {string} userUid - uid пользователя
   * @param {object} latencies - время задержек для каждого региона
   */
  setNGRegionsLatencies(userUid: string, latencies: { [key: string]: number }) {
    return this.ajax.post<void>(
      `${process.env.NEXT_PUBLIC_NG_CENTRAL_API}playerLatency/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pId: userUid,
          byRegion: latencies,
        }),
      }
    );
  }

  /**
   * Осуществляет запрос о получении задержек для регионов ng.
   * @param {string} userUid - uid пользователя
   */
  getNGPlayerRegionsLatencies(userUid: string) {
    return this.ajax.post<INGPlayerLatencies>(
      `${process.env.NEXT_PUBLIC_NG_CENTRAL_API}playerLatency/getByCriteria/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          includedPIds: [userUid],
        }),
      }
    );
  }

  /**
   * Осуществляет запрос о получении активных игр на региональном сервере ng.
   * @param {string} userUid - uid пользователя
   * @param {string} region - id региона
   */
  getNGPlayerRegionGames({
    region,
    userUids,
    gameIds,
  }: {
    region: string;
    userUids?: string[];
    gameIds?: string[];
  }) {
    return this.ajax.post<IGameState[]>(
      `${getNGRegionUrl(region)}api/v1/games/getByCriteria/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          includedPIds: userUids ? userUids : undefined,
          includedGIds: gameIds ? gameIds : undefined,
        }),
      }
    );
  }

  getNGGame(gameId: string, region: string | null) {
    return this.ajax.get<IGameState | void>(
      `${getNGRegionUrl(region)}game/${gameId}/api/v1/`
    );
  }
}

export const appService = new AppService({ instance: httpInstance });
