var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dev.chessarena.com@2.25.0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { HttpClient } from '@sentry/integrations';

const chessgunIgnoredErrors = [
  'Lan move is not valid',
  'Wrong piece',
  "Can't find piece",
  "Can't parse san move",
  'Expected white to move',
  'Expected black to move',
  'Wrong lan',
];

const gtmIgnoredErrors = [
  'fbq is not defined',
  "Can't find variable: fbq",
  'ttq is not defined',
  "Can't find variable: ttq",
  'window.lintrk is not a function',
];

const chessarenaIgnoredErrors = [
  'Minified React error #425',
  'Minified React error #418',
  'Minified React error #423',
  'aborted',
];

const ignoredErrors = [
  ...chessgunIgnoredErrors,
  ...gtmIgnoredErrors,
  ...chessarenaIgnoredErrors,
];

if (process.env.NEXT_PUBLIC_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: `${process.env.NEXT_PUBLIC_PROJECT_NAME}@${process.env.NEXT_PUBLIC_VERSION}`,
    tracesSampleRate: 0.1,
    integrations: [
      new HttpClient({
        failedRequestStatusCodes: [[500, 599]],
        sendDefaultPii: true,
      }),
    ],

    beforeSend: (event, hint) => {
      const error = hint.originalException;

      if (ignoredErrors.find((item) => error?.message?.includes(item)))
        return null;

      return event;
    },
  });
}
