import { CASTLING_SANS, figureType, SQUARES, W_KING, W_KNIGHT, W_BISHOP, W_QUEEN, W_ROOK, } from "../_constants";
export const parseAnalysisItemNew = (item) => {
    const { fen, depth, multipv } = item;
    const result = {
        fen,
        depth,
        multipv: [],
    };
    multipv.forEach(({ line, line_eval, mate_in }, index) => {
        const $line = [];
        line.forEach((item) => {
            const $from = SQUARES[item.lan.slice(0, 2)];
            const $to = SQUARES[item.lan.slice(2, 4)];
            const $san = item.san;
            const $lan = item.lan;
            const $type = CASTLING_SANS.includes($san)
                ? figureType.KING
                : [W_KING, W_KNIGHT, W_ROOK, W_BISHOP, W_QUEEN].includes($san[0])
                    ? $san[0].toLowerCase()
                    : figureType.PAWN;
            $line.push({
                from: $from,
                to: $to,
                san: $san,
                lan: $lan,
                type: $type,
            });
        });
        result.multipv.push({
            line_eval,
            mate_in,
            line: $line,
        });
    });
    return result;
};
